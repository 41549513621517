//site
$primary-h: 229 !default;
$primary-s: 76 !default;
$primary-b: 24 !default;

$screen-xs-max: 575px !default;
$screen-sm-max: 767px !default;
$screen-md-max: 991px !default;

$white: #ffffff; // TODO remove
$primary: #0f206c;
$secondary: #121d4d;
$tertiary: #ffd500;

$header-bg-color: #ffffff;
$header-center-bg-color: $header-bg-color;

$link-color: $primary;
$tertiary-text: $primary;

$headings-color: #303031;

$light-blue: #e9f1ff;

$subsite-suei-primary: #4c6f61;
$subsite-suei-primary-text: color-contrast($subsite-suei-primary);
$subsite-suei-secondary: #4c6f61;
$subsite-suei-secondary-text: color-contrast($subsite-suei-secondary);
$subsite-suei-tertiary: #4c6f61;
$subsite-suei-tertiary-text: color-contrast($subsite-suei-tertiary);
$subsite-suei-link-color: $subsite-suei-primary;

$subsite-giovani-primary: #00679c;
$subsite-giovani-primary-text: color-contrast($subsite-giovani-primary);
$subsite-giovani-secondary: #00679c;
$subsite-giovani-secondary-text: color-contrast($subsite-giovani-secondary);
$subsite-giovani-tertiary: #00679c;
$subsite-giovani-tertiary-text: color-contrast($subsite-giovani-tertiary);
$subsite-giovani-link-color: $subsite-giovani-primary;

$subsite-polizia-municipale-primary: #414574;
$subsite-polizia-municipale-primary-text: color-contrast(
  $subsite-polizia-municipale-primary
);
$subsite-polizia-municipale-secondary: #414574;
$subsite-polizia-municipale-secondary-text: color-contrast(
  $subsite-polizia-municipale-secondary
);
$subsite-polizia-municipale-tertiary: #414574;
$subsite-polizia-municipale-tertiary-text: color-contrast(
  $subsite-polizia-municipale-tertiary
);
$subsite-polizia-municipale-link-color: $subsite-polizia-municipale-primary;

$subsite-diritti-primary: #5c245e;
$subsite-diritti-primary-text: color-contrast($subsite-diritti-primary);
$subsite-diritti-secondary: #5c245e;
$subsite-diritti-secondary-text: color-contrast($subsite-diritti-secondary);
$subsite-diritti-tertiary: #5c245e;
$subsite-diritti-tertiary-text: color-contrast($subsite-diritti-tertiary);
$subsite-diritti-link-color: $subsite-diritti-primary;

$subsite-cultura-primary: #841d1d;
$subsite-cultura-primary-text: color-contrast($subsite-cultura-primary);
$subsite-cultura-secondary: #841d1d;
$subsite-cultura-secondary-text: color-contrast($subsite-cultura-secondary);
$subsite-cultura-tertiary: #841d1d;
$subsite-cultura-tertiary-text: color-contrast($subsite-cultura-tertiary);
$subsite-cultura-link-color: $subsite-cultura-primary;

$subsite-scuola-primary: #bb5a09;
$subsite-scuola-primary-text: color-contrast($subsite-scuola-primary);
$subsite-scuola-secondary: #bb5a09;
$subsite-scuola-secondary-text: color-contrast($subsite-scuola-secondary);
$subsite-scuola-tertiary: #bb5a09;
$subsite-scuola-tertiary-text: color-contrast($subsite-scuola-tertiary);
$subsite-scuola-link-color: $subsite-scuola-primary;
