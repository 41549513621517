.it-footer {
  a {
    text-decoration: underline;

    &:hover {
      color: transparentize($color: white, $amount: 0.15) !important;
    }
  }

  .it-footer-main {
    background-color: $primary;

    section.border-top {
      border: 0 !important;
    }

    figure.footer-logo {
      margin: 1rem 1rem 1rem 0;
    }

    .it-brand-text {
      padding-left: 1rem;

      h2 {
        font-size: 32px !important;
      }
    }

    .row {
      & > div {
        padding: 0 0.5rem;
      }

      h4 {
        display: flex;
        align-items: center;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        border-bottom: 1px solid #fff;
      }
    }

    .link-list-wrapper ul li a,
    h4 a {
      text-decoration: none;
    }
  }

  .it-footer-small-prints {
    background-color: $primary;

    .it-footer-small-prints-list {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 1rem;
        right: 1rem;
        height: 1px;
        background-color: white;
      }
    }
  }

  @media (max-width: map-get($map: $grid-breakpoints, $key: 'sm') - 1px) {
    .row > div {
      width: calc(100% - 72px);
    }
  }
}
