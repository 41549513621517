.simple-list-template-cosa-serve {
  tr.rowservizio {
    &.even {
      background-color: #f9f8f8;
    }

    &.odd {
      background-color: #ddd;
    }

    li.colonnaservizio {
      display: table-cell;
      list-style: none;

      &.title {
        width: 20%;
        padding-right: 20px;
      }

      &.ufficioresponsabile {
        width: 20%;
      }
    }
  }
}
