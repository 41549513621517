.block.cta-block {
  .cta-block-wrapper {
    &.side-image {
      background-color: $light-blue;

      &.has-image {
        figure {
          left: 50%;
          width: 50%;
          z-index: 0;

          &::after {
            display: none;
          }
        }
      }

      .cta-tile-text {
        margin: 0;
        width: 48%;
        text-align: left;

        &,
        & h2,
        & h3 {
          color: $headings-color;
        }
      }

      .cta-link a {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 0.77777778rem;
        padding: 0;

        svg {
          fill: $primary;
          margin-left: 0.5em;
        }
      }

      .cta_title,
      .cta_content {
        .public-DraftEditor-content,
        .public-DraftStyleDefault-block {
          text-align: left;
        }
      }

      @media (max-width: map-get($grid-breakpoints, 'md') - 1px) {
        flex-direction: column;
        height: auto;

        &.full-width {
          padding-top: 0;
          padding-bottom: 30px;
        }

        &.has-image {
          figure {
            position: static;
            width: 100%;
          }
        }

        .cta-tile-text {
          width: 100%;
        }
      }
    }
  }
}
