.weather_alert{
  font-size: 1.5em;
  svg{
    padding-right: 12px;
  }
  i{
    padding-right: 10px;
  }
  .card{
    .card-body{
      padding: 10px;
    }
  }
  div.item {
    margin-bottom: 15px;
    span.rss_meteo_date{
        font-size:1.3rem;
    }
    &.verde,
    &.giallo{
      .card{
        background-color: #f0c250;
        a{
          color: #000;
          text-decoration: none;
        }
      }
    }
    &.arancione{
      .card{
        background-color: #eb973f;
        a{
          color: #000;
          text-decoration: none;
        }
      }
    }
    &.rosso{
      .card{
        background-color: #a32219;
        a{
          color: #FFF;
          text-decoration: none;
        }
      }
    }
    a{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      padding-left: 20px;
      padding-top: 10px;
    }
  }
  @media (max-width: map-get($grid-breakpoints, 'lg') - 1px) {
    .card{
      .card-body{
        padding: 24px;
      }
    }
  }
  @media (max-width: map-get($grid-breakpoints, 'md') - 1px) {
    .card{
      .card-body{
        padding: 24px;
      }
    }
  }
  @media (max-width: map-get($grid-breakpoints, 'sm') - 1px) {
   .card{
      .card-body{
        padding: 38px;
      }
    }

  }
}
