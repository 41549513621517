body.contenttype-faqfolder {
  .faqfolder-view {
    .icon-argument-container {
      float: left;
      margin-bottom: 0 !important;
      margin-top: 0.5rem;
      margin-right: 1.7em;
    }
  }

  .faq-tree {
    .faq-folder {
      .folder-title {
        .icon-argument-container {
          display: inline-flex;
          margin-right: 0.8em;
        }

        .icon {
          width: 24px;
          height: 24px;
          margin-right: 0;
        }
      }
    }
  }
}
