.complete-block-links-template {
  h2.mb-4 {
    margin-bottom: 64px !important;
  }

  .card.card-bg {
    background-color: $primary;
  }

  h3.card-title {
    font-size: 1.4rem !important;
    margin-bottom: 0 !important;
  }
}
