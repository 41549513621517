.card {
  .card-body {
    .category-top {
      .tags {
        font-weight: 700;
      }

      .text:not(:empty) + .tags::before {
        content: ' - ';
        font-weight: 300;
      }
    }
  }
}
