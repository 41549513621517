@import 'custom/site/cms';

.public-ui {
  @import 'custom/main';
  //import here your site customization styles
  @import 'custom/layout/header';
  @import 'custom/layout/_footer.scss';
  @import 'custom/feedback';

  @import 'custom/cards/card_category';
}

@import 'custom/views/common';
@import 'custom/views/faq_folder';
@import 'custom/views/search';
@import 'custom/views/search_homepage';
@import 'custom/views//servizio';

@import 'custom/blocks/arguments_in_evidence';
@import 'custom/blocks/card_slideup_text';
@import 'custom/blocks/complete_block_links';
@import 'custom/blocks/cta_block';
@import 'custom/blocks/in_evidence';
@import 'custom/blocks/search_sections';
@import 'custom/blocks/servizi_cosa_serve';
@import 'custom/blocks/card_rss_meteo';

.public-ui a,
a {
  &.btn.btn-tertiary {
    .icon {
      fill: $tertiary-text;
    }
  }
}

.icon-argument-container {
  background-color: $tertiary;

  .icon,
  svg {
    color: $tertiary-text;
    fill: $tertiary-text;
  }
}

.scroll-to-top {
  svg {
    color: $tertiary-text;
    fill: $tertiary-text;
  }
}

// customizzazione per testo con sfondo primary - testo scuro non si legge in edit
.block.text .DraftEditor-root .public-DraftStyleDefault-block {
  background-color: transparentize($color: #fff, $amount: 0.8);
}
