.argumentInEvidence {
  .argumentInEvidence-background {
    background-color: $light-blue;
    + .argumentsCardsWrapper h2,
    + .volto-subblocks-wrapper .argumentsCardsWrapper h2 {
      color: $headings-color !important;
    }
  }

  .argumentsCardsWrapper {
    padding-top: 48px !important;
  }

  .card {
    .card-body {
      &,
      .card-text {
        font-size: 0.889rem;
      }

      a {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
