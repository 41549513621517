.container .block .searchSections:not(.button) {
  .searchSections-background picture {
    height: 100%;

    img {
      height: 100%;
      object-position: center;
      object-fit: cover;
    }
  }

  .searchContainer {
    margin: 120px 0;

    .searchbar {
      align-items: center;

      .icon {
        margin-right: 14px;
        fill: #d1cfcf;
      }
    }
  }

  .buttonsContainer .btn-tertiary {
    color: $primary;
  }
}
