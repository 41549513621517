.block.listing {
  .in-evidence {
    h2.mb-4 {
      margin-bottom: 64px !important;
    }

    .listing-item h3.card-title a {
      color: $headings-color;
    }
  }

  &.inEvidenceTemplate {
    margin-bottom: 4rem;
  }
}
