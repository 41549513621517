.parma-search-view {
  .solr-search-button {
    margin-left: auto;
    align-self: center;

    .fa-external-link-alt {
      margin-left: 0.8em;
    }
  }
}
