// a {
//   text-decoration: none;

//   &:hover {
//     text-decoration: underline;
//   }
// }

.card .card-body .card-text {
  font-size: 1rem;
}

.breadcrumb-container .breadcrumb .breadcrumb-item a {
  color: $secondary !important;
  text-decoration: underline !important;
  font-weight: 600;
}

.link-list-wrapper.menu-link-list h3:after,
.link-list-wrapper.menu-link-list h4:after {
  background-color: $tertiary !important;
}

.navbar.it-navscroll-wrapper .link-list-wrapper ul li a {
  span {
    color: $secondary !important;
  }
  &.active {
    border-left: 2px solid $secondary !important;
  }
}

h5.card-title {
  margin-bottom: 0 !important;
}
.card-title a {
  color: $secondary;
  font-size: 1.333rem;
}

.point-list-wrapper .point-list .point-list-aside.point-list-warning {
  color: $secondary !important;
  border-color: $secondary !important;
  &::after {
    background-color: $secondary !important;
  }
}

.draftjs-buttons a,
.cms-ui .draftjs-buttons a {
  background-color: $tertiary !important;
  color: $secondary !important;
}

.section.section-muted {
  background: $light-blue;
}

.bg-light {
  background-color: $light-blue !important;
}

.collapse-header *[data-toggle='collapse'][aria-expanded='false'] {
  color: $secondary !important;
}

.collapse-header *[data-toggle='collapse'] {
  color: #888fac;
}

.callout-bg,
.callout-bg,
.cms-ui .callout-bg {
  color: $primary-text !important;
}
