.card-slide-text-template {
  h2 {
    margin-bottom: 60px !important;
  }

  .box {
    .category {
      background-color: $tertiary;
      color: $tertiary-text;
      font-weight: 600;
      letter-spacing: 0.5px;

      &::after {
        border-top: 28px solid $tertiary;
      }
    }
  }

  .link-button.my-4 {
    margin-bottom: 64px !important;
  }
}
